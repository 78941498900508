import React, { useEffect, useState } from 'react';
import { url } from '../../const/url';
import EditableItemList from './EditableItem';
import LoginForm from './login';
import { useAuth } from '../../context/auth';

function MenuEdit() {
    const [data, setData] = useState([]);
    const [selectedObject, setSelectedObject] = useState(null);
    const { signedToken, setSignedToken } = useAuth();

    useEffect(() => {
        fetch(`${url}/menu`, { method: 'GET' }).then((response) => {
            response.json().then((data) => {
                setData(data);
            })
        }).catch((error) => {
            console.log(error);
        });
    }, [])


    const handleChange = (event) => {
        const selectedId = parseInt(event.target.value);
        const selected = data[selectedId]
        setSelectedObject(selected); 
    };
    const handleLogout = () => {
        setSignedToken(null);
        localStorage.clear();
    };


    if (!signedToken) {
        return <div className='loginForm'>
            <LoginForm />
        </div>
    }

    return (
        <>
            {data &&
                <div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div >
                            <h3>Select an option:</h3>
                            <select onChange={handleChange}>
                                <option value="">Select an option</option>
                                {data.map((item, index) => (
                                    <option key={item._id} value={index}>
                                        {item.category.en}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <h3 style={{ cursor: 'pointer' }} onClick={handleLogout}> logout </h3>
                    </div>


                    {selectedObject && (
                        <EditableItemList selectedObject={selectedObject.items} id={selectedObject._id} setData={setData} />
                    )}
                </div>
            }
        </>

    );
}

export default MenuEdit;
