import React, { useEffect, useState } from 'react';
import { url } from '../../const/url';
import ImageContainer from '../../components/ImgComponent';
import { useAuth } from '../../context/auth';

const EditableItemList = ({ selectedObject, id, setData }) => {
  // Initial state based on the provided selected item
  const [items, setItems] = useState(selectedObject);
  const { signedToken } = useAuth();
  useEffect(() => {
    setItems(selectedObject)
  }, [selectedObject])

  // State for new item addition
  const [newItem, setNewItem] = useState({
    name: { en: "", gr: "" },
    val: "",
    price: 0,
    key: ""
  });

  // Handle input changes for existing items
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: value
    };
    setItems(updatedItems);
  };

  // Handle name change for multi-lingual name fields
  const handleItemNameChange = (index, lang, value) => {
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      name: {
        ...updatedItems[index].name,
        [lang]: value
      }
    };
    setItems(updatedItems);
  };

  // Save button for updating an item
  const handleSave = (index) => {
    const updatedItem = items[index];
    fetch(`${url}/updateItem/${id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': signedToken },
      body: JSON.stringify({ newItem: updatedItem })
    })
      .then((response) => {
        response.json().then((data) => {
          // console.log('saved');
          setData((prevData) => {
            return prevData.map(category => {
              if (category._id?.toString() === id?.toString()) {
                return {
                  ...category,
                  items: category.items.map(item => {
                    if (item._id?.toString() === updatedItem._id?.toString()) {
                      return updatedItem;
                    }
                    return item;
                  })
                }
              }
              return category
            })
          })
        })
      })
      .catch((error) => {
        console.log(error);
      });

  };
  // Save button for updating an item
  const handleDelete = (index) => {
    // Confirm deletion (optional)
    if (window.confirm("Are you sure you want to delete this item?")) {
      fetch(`${url}/delete/${id}/${items[index]._id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `${signedToken}` },
      })
        .then((response) => {
          response.json().then((data) => {
            const updatedItems = items.filter(item => item._id !== items[index]._id);
            setItems(updatedItems);
            setData((prevData) => {
              return prevData.map(category => {
                if (category._id?.toString() === id?.toString()) {
                  return {
                    ...category,
                    items: category.items.filter(item => item._id !== items[index]._id)
                  }
                }
                return category
              })
            })
          })
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  // Handle new item addition
  const handleNewItemChange = (field, value) => {
    setNewItem({ ...newItem, [field]: value });
  };
  const handleNewItemFrozenChange = () => {
    setNewItem({ ...newItem, isFrozen: !newItem?.isFrozen });
  };

  // Handle new item name change for multi-lingual fields
  const handleNewItemNameChange = (lang, value) => {
    setNewItem({
      ...newItem,
      name: {
        ...newItem.name,
        [lang]: value
      }
    });
  };

  const handleAddNewItem = () => {
    if (newItem.name.en && newItem.price) {
      newItem.key = newItem.name.en.replace(/ /g, "_").toLocaleLowerCase();

      fetch(`${url}/updateItem/${id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `${signedToken}` },
        body: JSON.stringify({ newItem: newItem })
      })
        .then((response) => {
          response.json().then((data) => {
            setData((prevData) => {
              return prevData.map(category => {
                if (category._id?.toString() === id?.toString()) {
                  return data.category;
                }
                return category;
              })
            })
            setItems(data.category.items);
            setNewItem({
              name: { en: "", gr: "" },
              val: "",
              price: 0,
              key: ""
            });
          })
        })
        .catch((error) => {
          console.log(error);
        });

    } else {
      alert("Please fill in all required fields");
    }
  };


  const handleImageUpload = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      // Simulate uploading the image to a server and getting the URL

      const newFileName = `${items[index].key}_${items[index]._id?.toString()}.jpg`;
      const renamedFile = new File([file], newFileName, { type: file.type });

      const formData = new FormData();
      formData.append('photo', renamedFile);

      const res = await fetch('http://localhost:8787/upload/menuImages', {
        method: 'POST',
        body: formData,
        headers: { 'Authorization': `${signedToken}` },
      });

      if (!res.ok) {
        window.alert(res.ok)
      }

      const data = await res.text();
      const updatedItems = [...items];
      updatedItems[index].image = JSON.parse(data).result;
      setItems(updatedItems);
    }
  };


  return (
    <div>
      {
        items &&
        <>
          <div>
            <h3>Editable Item List</h3>
          </div>

          <table>
            <thead>
              <tr>
                <th>Αγγλικό Όνομα</th>
                <th>Ελληνικό Όνομα</th>
                <th>Value</th>
                <th>Τιμή</th>
                <th>Κατεψυγμένα</th>
                <th>Ενέργεια</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={item._id}>
                  <td>
                    <input
                      type="text"
                      value={item.name.en}
                      onChange={(e) => handleItemNameChange(index, "en", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={item.name.gr}
                      onChange={(e) => handleItemNameChange(index, "gr", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={item.val || ""}
                      onChange={(e) => handleItemChange(index, "val", e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.price}
                      onChange={(e) => handleItemChange(index, "price", e.target.value)}
                    />
                  </td>
                  <td>
                    {item.isFrozen && console.log(item)}
                    <input
                      type="checkbox"
                      value={item.isFrozen ? true : false}
                      checked={item.isFrozen}
                      onChange={(e) => handleItemChange(index, "isFrozen", !item.isFrozen)}
                    />
                  </td>
                  <td>
                    <ImageContainer src={item.image} edit={true} />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      id={`file-upload-${index}`}
                      onChange={(e) => handleImageUpload(index, e)}
                    />
                    <label htmlFor={`file-upload-${index}`} style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
                      Upload Image
                    </label>
                  </td>
                  <td>
                    <button onClick={() => handleSave(index)}>Αποθήκευση</button>
                  </td>
                  <td>
                    <button className='delete' onClick={() => handleDelete(index)}>Διαγραφή</button>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <input
                    type="text"
                    placeholder="Αγγλικό Όνομα"
                    value={newItem.name.en}
                    onChange={(e) => handleNewItemNameChange("en", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Ελληνικό Όνομα"
                    value={newItem.name.gr}
                    onChange={(e) => handleNewItemNameChange("gr", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Value"
                    value={newItem.val}
                    onChange={(e) => handleNewItemChange("val", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="Τιμή"
                    value={newItem.price}
                    onChange={(e) => handleNewItemChange("price", e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    placeholder="Κατεψυγμένα"
                    value={newItem.isFrozen}
                    onChange={(e) => handleNewItemFrozenChange()}
                  />
                </td>
                <td>
                  <button onClick={handleAddNewItem}>Προσθήκη</button>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      }
    </div>
  );
};

export default EditableItemList;
