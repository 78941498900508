import React, { useEffect, useState } from 'react';

const ImageContainer = ({ src, edit }) => {
    const [imageExists, setImageExists] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [cacheBustedSrc, setCacheBustedSrc] = useState(src);

    const handleImageError = () => {
        setImageExists(false);
    };

    const handleImageClick = () => {
        setIsFullscreen(true);
    };

    const handleCloseFullscreen = () => {
        setIsFullscreen(false);
    };

    const getImageUrl = (originalUrl) => {
        // Encode the original URL to use it as a query parameter in the proxy URL
        const encodedUrl = encodeURIComponent(originalUrl);
        // Return the new proxy URL
        return `https://metaximas.gr/image-proxy.php?url=${encodedUrl}`;
    }

    useEffect(() => {
        getImageUrl(src);
        if (src) {
            let newURL = getImageUrl(src);
            setCacheBustedSrc(`${newURL}?t=${new Date().getTime()}`);
        }
    }, [src])

    useEffect(() => {

    }, [edit])

    if (imageExists && cacheBustedSrc !== undefined) {
        return (<>
            <img src={cacheBustedSrc} alt={'alt'} className="photo" onError={handleImageError} onClick={handleImageClick} />
            {isFullscreen && (
                <div className="fullscreen-overlay" onClick={handleCloseFullscreen}>
                    <span className="close-button" onClick={handleCloseFullscreen}>&times;</span>
                    <img
                        src={cacheBustedSrc}
                        alt={'alt'}
                        className="fullscreen-image"
                    />
                </div>
            )}
        </>)
    } else {
        return (
            <div className={edit ? 'emptyPhoto' : ''}>

            </div>
        )
    }
};

export default ImageContainer;
