import React from 'react';
import { Element } from 'react-scroll';
import ImageContainer from '../../../components/ImgComponent.js';

import { useTranslation } from 'react-i18next';
// import { url } from '../../../const/url.js';

const Menu = ({ items }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="section-center">
            {items.map((item, index) => {
                return (
                    <Element name={item?.category?.[i18n.language]} key={index} className="category">
                        <h3>{item?.category?.[i18n.language] || 'test'}</h3>
                        <ul>
                            {item?.items.map((value, index) => {
                                const { name, val, price, image, isFrozen } = value;
                                return (
                                    <article key={index} className="menu-item">
                                        <div className='icon-item'>
                                            {image && <ImageContainer src={image} />}
                                            <h4 className='item-title'>{name?.[i18n.language] || name.gr}</h4>
                                            {isFrozen &&
                                                <div className='item-title-isFrozen'>
                                                    <span>
                                                        *
                                                    </span>
                                                </div>
                                            }
                                            {val &&
                                                <div className='item-title-val'>
                                                    <span>
                                                        {t(val)}
                                                    </span>
                                                </div>
                                            }

                                        </div>
                                        {price > 0 && <h4 className="price">{price}€</h4>}
                                    </article>
                                );
                            })
                            }
                        </ul>
                    </Element>
                );
            })}
        </div>
    )
};

export default Menu;
