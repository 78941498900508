import React from "react";
import {
    Route,
    Routes
} from "react-router-dom";
import MenuView from "./pages/menu/MenuView";
import MenuEdit from "./pages/menuEdit/MenuEdit";
import { AuthProvider } from './context/auth';

export default function Map() {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/edit_menu" Component={MenuEdit} />
                <Route path="/*" Component={MenuView} />
            </Routes>
        </AuthProvider>
    );
}
