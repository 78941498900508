// src/AuthContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a Context for the Auth Token
const AuthContext = createContext();

// Create a Provider component
export const AuthProvider = ({ children }) => {
    const [signedToken, setSignedToken] = useState(null);
    useEffect(() => {
        setSignedToken(localStorage.getItem('token'))
    }, [])

    return (
        <AuthContext.Provider value={{ signedToken, setSignedToken }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};
