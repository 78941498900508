import React, { useEffect, useState } from 'react';
import Lottie from "lottie-react";

function Loader() {
    const [selectedJson, setSelectedJson] = useState(null);

    useEffect(() => {
        // Dynamically require all JSON files in the folder
        const importAll = (r) => r.keys().map(r);
        const jsonFiles = importAll(require.context('./', false, /\.json$/));

        const randomJson = jsonFiles[Math.floor(Math.random() * jsonFiles.length)];

        setSelectedJson(randomJson);
    }, []);
    return (
        <Lottie
            animationData={selectedJson}
            style={{ height: "80vh" }}
        />

    )
}
export default Loader;
