import i18next from "i18next";
import { initReactI18next } from "react-i18next";


i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'gr',
        resources: {
            en: {
                translation: {
                    "frozen": "* Products marked with stars are frozen"
                }
            },
            gr: {
                translation: {
                    "frozen": "* Τα προιόντα με αστέρια είναι κατεψυγμένα."
                }
            }
        }
    })